// import { REACT_APP_BASE_URL } from "@env";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { isSuperAdmin } from "../lib/helperFuctions";
// type prepareHeaders = (
//   headers: Headers,
//   api: {
//     getState: () => unknown
//     extra: unknown
//     endpoint: string
//     type: 'query' | 'mutation'
//     forced: boolean | undefined
//   }
// ) => Headers | void

/**
 * Base API service to be used across the application.
 * Endpoints can be injected using the .injectEndpoints method.
 */

const baseApiService = createApi({
  reducerPath: "baseApiReducer",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/${isSuperAdmin() ? "admin" : "store"}`,
    // baseUrl: `${"http://localhost:5000/api"}/${isSuperAdmin() ? "admin" : "store"}`,
    prepareHeaders: async (headers) => {
      const token = await localStorage.getItem("authToken");
      headers.set("Authorization", `Bearer ${token}`);

      return headers;
    },
    // credentials: "include",
    /*
     * This is added to timeout the API in case no response is received in 10s
     */
    timeout: 10000,
  }),
  endpoints: () => ({}),
});

export default baseApiService;
