import baseApiService from "./base-api.service";

const ORDER_TAG = "ORDER";

/**
 * Service to fetch data from the API for an endpoint.
 */
const OrderApi = baseApiService
  .enhanceEndpoints({
    addTagTypes: [ORDER_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getOrders: build.query({
        query: (params) => ({
          url: "/order",
          params: params,
        }),
        providesTags: [ORDER_TAG],
      }),
      updateOrder: build.mutation({
        query: (data) => {
          return {
            url: `/order/${data?.id}`,
            method: "PATCH",
            body: data?.submitData,
          };
        },
      }),
      updateOrderPickupTime: build.mutation({
        query: (data) => {
          return {
            url: `/updateOrderPickupTime/${data?.id}`,
            method: "PATCH",
            body: data,
          };
        },
      }),
    }),
    overrideExisting: true,
  });

export const { useLazyGetOrdersQuery, useUpdateOrderMutation, useUpdateOrderPickupTimeMutation } =
  OrderApi;
