import {
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FormButtons from "components/FormButtons";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import MDButton from "components/MDButton";
import PreviewImage from "components/PreviewImage";
import Loader from "../../../components/Loader";
import Constants from "../../../lib/Constants";
import { validate } from "../../../lib/joiHelper";
import { useLazyGetIngredientsQuery } from "../../../services/ingedient.service";
import { useLazyGetCategoriesQuery } from "../../../services/product-category.service";
import {
  useAddProductMutation,
  useLazyGetProductQuery,
  useUpdateProductMutation,
} from "../../../services/product.service";
import "../product.css";
import DynamicInputs from "./DynamicInputs";
import PriceInput from "./PriceInput";
import {
  createNonPizzaSchema,
  createPizzaSchema,
  updateNonPizzaSchema,
  updatePizzaSchema,
} from "./schema";

function AddProduct() {
  const fieldObj = {
    productCategory: "",
    label_color: "",
    name: "",
    image: "",
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [inputData, setInputData] = useState({ ...fieldObj, label_color: "#000000" });
  const [error, setError] = useState({ ...fieldObj });
  const [categories, setCategories] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageType, setPageType] = useState("");

  const [getCategories] = useLazyGetCategoriesQuery();
  const [getIngredients] = useLazyGetIngredientsQuery();
  const [addProductTrigger] = useAddProductMutation();
  const [updateProductTrigger] = useUpdateProductMutation();
  const [getProductTrigger] = useLazyGetProductQuery();

  const fetchCategories = useCallback(async () => {
    const { data, isSuccess } = await getCategories();

    if (isSuccess) {
      setCategories(data?.data);
    }
  });

  const fetchIngredients = useCallback(async () => {
    const { data, isSuccess } = await getIngredients();

    if (isSuccess) {
      if (data?.data?.length) {
        const modifiedIngredients = data?.data?.map((ingedient) => {
          return { label: ingedient?.name, value: ingedient?.id };
        });
        setIngredients(modifiedIngredients);
      }
    }
  });

  const fetchProduct = useCallback(async (id) => {
    setLoading(true);
    const { data: apiData, isSuccess, isError } = await getProductTrigger(id);
    if (isSuccess) {
      const dataClone = { ...apiData?.data, productCategory: apiData?.data?.productCategory?.id };
      const categoryName = apiData?.data?.productCategory?.name;
      if (dataClone?.ingrediants?.required?.length || dataClone?.ingrediants?.optional?.length) {
        dataClone.defaultIngredients = dataClone?.ingrediants?.required?.map((item) => item?.value);
        dataClone.optIngredients =
          dataClone?.ingrediants?.optional?.map((item) => item?.value) || [];
      }

      setInputData(dataClone);
      setSelectedCategoryName(categoryName);
    }
    setLoading(false);
    if (isError) {
      navigate("/products");
    }
  });

  useEffect(() => {
    const page = location?.state?.pageType || "add";
    fetchCategories();
    if (page !== "add") {
      fetchProduct(params?.productId);
    }
    setPageType(page);
  }, []);

  useEffect(() => {
    if (selectedCategoryName?.toLowerCase() !== Constants.PRODUCT_CATEGORY.PIZZA?.toLowerCase()) {
      const dataClone = { ...inputData };

      delete dataClone?.defaultIngredients;
      delete dataClone?.optIngredients;
      delete dataClone?.sub_category;
      setInputData(dataClone);
    } else {
      const dataClone = { ...inputData, label_color: inputData?.id ? inputData?.label_color : "" };
      setInputData(dataClone);
      fetchIngredients();
    }
  }, [inputData?.productCategory]);

  const handleOnChange = (name, value) => {
    const dataClone = { ...inputData, [name]: value };
    setInputData(dataClone);
  };

  const handleDynamicChange = (state) => {
    const dataClone = { ...inputData, ...state };
    setInputData(dataClone);
  };

  const handleImageSelect = (selectedImage) => {
    const inputDataClone = { ...inputData };
    inputDataClone.image = selectedImage;
    setInputData(inputDataClone);
    if (error?.image) {
      setError({ ...error, image: null });
    }
  };

  const getSchema = () => {
    if (selectedCategoryName?.toLowerCase() === Constants.PRODUCT_CATEGORY.PIZZA?.toLowerCase()) {
      if (inputData?.id) {
        return updatePizzaSchema(t);
      }
      return createPizzaSchema(t);
    }
    if (inputData?.id) {
      return updateNonPizzaSchema(t);
    }
    return createNonPizzaSchema(t);
  };

  const onSubmitHandler = async () => {
    setLoading(true);
    const schema = getSchema();
    const errorClone = validate(inputData, schema);
    if (errorClone) {
      setError(errorClone);
    } else {
      const submitData = {
        ...inputData,
        ingrediants: {
          required: inputData?.defaultIngredients,
          optional: inputData?.optIngredients,
        },
        category_id: inputData.productCategory,
      };
      delete submitData?.defaultIngredients;
      delete submitData?.optIngredients;
      delete submitData?.productCategory;
      if (typeof submitData?.image === "string") {
        delete submitData.image;
      }
      const submitFormData = new FormData();
      Object.keys(submitData).forEach((key) => {
        const value =
          typeof submitData[key] === "object" && key !== "image"
            ? JSON.stringify(submitData[key])
            : submitData[key];
        submitFormData.append(key, value);
      });

      let result;
      if (inputData.id) {
        result = await updateProductTrigger({ submitData: submitFormData, id: inputData?.id });
      } else {
        result = await addProductTrigger(submitFormData);
      }
      setLoading(false);
      if ("error" in result) {
        toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
      } else {
        toast.success(result?.data?.msg);
        navigate("/products");
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {t("product:ADD_PRODUCT")}
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="product-category-label" required>
                            {t("product:PRODUCT_CATEGORY")}
                          </InputLabel>
                          <Select
                            name="productCategory"
                            labelId="product-category-label"
                            id="product-category"
                            label={t("product:PRODUCT_CATEGORY")}
                            sx={{ height: 43 }}
                            onChange={(event) =>
                              handleOnChange(event.target.name, event.target.value)
                            }
                            error={error?.productCategory?.length > 0}
                            fullWidth
                            value={inputData.productCategory}
                          >
                            {categories?.map((category) => (
                              <MenuItem
                                key={category?.id}
                                value={category?.id}
                                onClick={() => {
                                  setSelectedCategoryName(category?.name);
                                }}
                              >
                                {category?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {error?.productCategory?.length > 0 && (
                            <FormHelperText error>{t(error?.productCategory)}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TextField
                            id="filled-basic"
                            label={t("common:NAME")}
                            name="name"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(event) =>
                              handleOnChange(event.target.name, event.target.value)
                            }
                            error={error?.name?.length > 0}
                            helperText={t(error?.name)}
                            value={inputData.name}
                          />
                        </FormControl>
                      </Grid>

                      {inputData?.productCategory && [
                        selectedCategoryName?.toLowerCase() ===
                          Constants.PRODUCT_CATEGORY.PIZZA?.toLowerCase() && (
                          <DynamicInputs
                            key="dynamic-input"
                            handleLabelChange={handleOnChange}
                            handleOnChange={handleDynamicChange}
                            t={t}
                            error={error}
                            ingredients={ingredients}
                            inputData={inputData}
                          />
                        ),
                        <Grid item xs={12} sm={12} key="price-input-grid">
                          <Grid container spacing={2}>
                            <PriceInput
                              key="price-input"
                              inputData={inputData}
                              handleOnChange={handleOnChange}
                              t={t}
                              error={error}
                              setError={setError}
                              selectedCategoryName={selectedCategoryName}
                            />
                          </Grid>
                        </Grid>,
                      ]}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className="borderedBox">
                      <PreviewImage
                        src={inputData.image}
                        alt={`${inputData.name.replaceAll(" ", "-")}-img`}
                      />
                    </div>
                    <div className="image-btn-container">
                      <label htmlFor="upload-photo">
                        <input
                          style={{ display: "none" }}
                          id="upload-photo"
                          name="upload-photo"
                          type="file"
                          onChange={(e) => handleImageSelect(e.target.files[0])}
                          accept={Constants.IMAGE_FILE_TYPES.join(",")}
                        />

                        <MDButton variant="gradient" color="info" component="span">
                          Upload Photo
                        </MDButton>
                      </label>
                      <MDButton
                        variant="outlined"
                        color="secondary"
                        style={{ marginLeft: "16px" }}
                        onClick={() => setInputData({ ...inputData, image: "" })}
                      >
                        Remove Photo
                      </MDButton>
                    </div>
                  </Grid>
                  {pageType !== "view" && (
                    <Grid item xs={12} sm={12}>
                      <FormButtons
                        removeTopBottomPadding
                        cancelLabel={t("common:CANCEL")}
                        submitLabel={pageType === "edit" ? t("common:UPDATE") : t("common:SAVE")}
                        submitHandler={onSubmitHandler}
                        cancelHandler={() => navigate("/products")}
                      />
                    </Grid>
                  )}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Loader open={loading} />
    </DashboardLayout>
  );
}

export default AddProduct;
