/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from "react";
import "./image.css";

const placeholderImage = "/images/placeholder.png";

const PreviewImage = ({ src, alt, ...props }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  if (src && typeof src !== "string") {
    src = URL.createObjectURL(src);
  }

  return (
    <>
      <img
        src={src || placeholderImage}
        alt={alt}
        {...props}
        className={`preview-image smooth-image image-${isImageLoaded ? "visible" : "hidden"}`}
        onLoad={() => setIsImageLoaded(true)}
      />
      {!isImageLoaded && (
        <div className="previewImage smooth-preloader">
          <span className="loader" />
        </div>
      )}
    </>
  );
};

export default PreviewImage;
