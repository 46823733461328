/* eslint-disable react/prop-types */
import { FormControl, FormHelperText, Grid, Icon, TextField } from "@mui/material";
import CountryDropDown from "components/CountryDropDown";
import MDButton from "components/MDButton";
import Constants from "lib/Constants";
import React, { useEffect, useState } from "react";

const PriceInput = ({ inputData, handleOnChange, t, error, setError, selectedCategoryName }) => {
  const [priceArray, setPriceArray] = useState();

  const getPriceInitializeStructure = () => {
    if (selectedCategoryName?.toLowerCase() === Constants.PRODUCT_CATEGORY.PIZZA?.toLowerCase()) {
      return { country: "", price: { large: "", small: "" } };
    }
    return { country: "", price: "" };
  };

  useEffect(() => {
    if (inputData?.productCategory) {
      const structure = [getPriceInitializeStructure()];
      setPriceArray(inputData?.price?.length > 0 ? [...inputData.price] : structure);
      setError({ ...error, price: structure });
      handleOnChange("price", structure);
    }
  }, [inputData?.productCategory]);

  const addNewPriceRow = () => {
    const priceArrayClone = [...priceArray];
    priceArrayClone.push(getPriceInitializeStructure());
    setPriceArray(priceArrayClone);
    handleOnChange("price", priceArrayClone);
  };

  const onPriceChange = (index, event, productType, pizzaType) => {
    // const priceArrayClone = [...priceArray];
    const priceArrayClone = JSON.parse(JSON.stringify(priceArray));
    if (productType === "other") {
      priceArrayClone[index].price = event.target.value;
    } else {
      priceArrayClone[index].price = {
        ...priceArrayClone[index].price,
        [pizzaType]: event.target.value,
      };
    }
    setPriceArray(priceArrayClone);
  };

  const onCountryChange = (index, event) => {
    const priceArrayClone = [...priceArray];
    priceArrayClone[index].country = event.target.value;
    setPriceArray(priceArrayClone);
  };

  const removePriceRow = (index) => {
    const priceArrayClone = [...priceArray];
    priceArrayClone.splice(index, 1);
    setPriceArray(priceArrayClone);
    handleOnChange("price", priceArrayClone);
  };

  const onBlurHandler = () => {
    handleOnChange("price", priceArray);
  };

  return (
    <>
      {priceArray?.length > 0 &&
        priceArray?.map((input, index) => (
          <React.Fragment key={index} style={{ paddingTop: "8px" }}>
            <Grid item xs={12} sm={4}>
              <CountryDropDown
                value={input?.country}
                onChange={(e) => onCountryChange(index, e)}
                onBlur={() => onBlurHandler()}
                error={error?.price?.[index]?.country?.length > 0}
              />
              {error?.price?.[index]?.country?.length > 0 && (
                <FormHelperText error>{error?.price?.[index]?.country}</FormHelperText>
              )}
            </Grid>
            {typeof input.price === "string" ? (
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <TextField
                    id="filled-basic"
                    label={t("common:PRICE")}
                    name="price"
                    variant="outlined"
                    type="number"
                    onChange={(event) => onPriceChange(index, event, "other")}
                    onBlur={() => onBlurHandler()}
                    error={error?.price?.[index]?.price?.length > 0}
                    helperText={error?.price?.[index]?.price}
                    value={input?.price}
                    fullWidth
                    inputprops={{
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <TextField
                      id="small-price"
                      label={t("product:SMALL")}
                      variant="outlined"
                      fullWidth
                      type="number"
                      onChange={(e) => onPriceChange(index, e, "pizza", "small")}
                      onBlur={() => onBlurHandler()}
                      error={error?.price?.[index]?.price?.small?.length > 0}
                      helperText={error?.price?.[index]?.price?.small}
                      value={input?.price?.small}
                      required
                      inputprops={{
                        inputProps: { min: 0 },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <TextField
                      id="large-price"
                      label={t("product:LARGE")}
                      onChange={(e) => onPriceChange(index, e, "pizza", "large")}
                      onBlur={() => onBlurHandler()}
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      error={error?.price?.[index]?.price?.large?.length > 0}
                      helperText={error?.price?.[index]?.price?.large}
                      value={input?.price?.large}
                      inputprops={{
                        inputProps: { min: 0 },
                      }}
                    />
                  </FormControl>
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={2}>
              {index !== 0 && (
                <MDButton
                  variant="contained"
                  style={{ backgroundColor: "#EC643C", color: "#FFF" }}
                  size="small"
                  circular
                  iconOnly
                  onClick={() => removePriceRow(index)}
                >
                  <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                </MDButton>
              )}
            </Grid>
          </React.Fragment>
        ))}

      <Grid item xs={12} sm={12} style={{ paddingTop: "8px" }}>
        <MDButton
          variant="contained"
          style={{ backgroundColor: "#EC643C", color: "#FFF" }}
          size="small"
          onClick={addNewPriceRow}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          &nbsp;{t("product:ADD_PRICE_VARIENT")}
        </MDButton>
      </Grid>
    </>
  );
};

export default PriceInput;
