import React, { useCallback, useEffect, useState } from "react";
import { Card, Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Loader from "components/Loader";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ExpandableTable from "examples/Tables/ExpandableTable";
import {
  useLazyGetOrdersQuery,
  useUpdateOrderMutation,
  useUpdateOrderPickupTimeMutation,
} from "services/order.service";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { element } from "prop-types";
import PickupTimeDialog from "./PickupTimeDialog";
function OrderList() {
  const { t } = useTranslation();
  const [PickupTimeDialogOpen, setPickupTimeDialogOpenOpen] = useState(false);

  const [orders, setOrders] = useState({
    total: 0,
    data: [],
  });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const [getOrders] = useLazyGetOrdersQuery();
  const [updateOrder] = useUpdateOrderMutation();
  const [updatePickupTime] = useUpdateOrderPickupTimeMutation();
  const [selectedRow, setSelectedRow] = useState(null);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    const { data, isSuccess, isError, error } = await getOrders({
      search,
      page: page + 1,
      limit: rowsPerPage,
    });

    if (isSuccess) {
      setOrders(data);
    }

    if (isError) {
      console.log(error);
    }
    setLoading(false);
  });

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [search, page, rowsPerPage]);

  const handleOrderUpdate = async (data) => {
    const ordersClone = { ...orders };

    ordersClone.data = ordersClone?.data?.map((product) => {
      if (product?.id === data?.id) {
        product = { ...product, status: data?.updatedStatus };
      }
      return product;
    });
    setOrders(ordersClone);

    const result = await updateOrder({
      submitData: { status: data?.updatedStatus },
      id: data?.id,
    });
    if ("error" in result) {
      toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    } else {
      toast.success(result?.data?.msg);
    }
  };

  const handleOpenDialog = (row) => {
    setSelectedRow(row);
    setPickupTimeDialogOpenOpen(true);
  };

  const handleCloseDialog = () => {
    setPickupTimeDialogOpenOpen(false);
  };

  const handleUpdatePickupTime = async (additionalTime, row) => {
    console.log(additionalTime, row.id);
    await updatePickupTime({
      additionalTime,
      id: row?.id,
    });
    // const updatedOrders = orders.data.map((order) => {
    //   if (order.id === row.id) {
    //     // Adding selected time to existing pickup time
    //     const existingTime = parseInt(order.pickupTime, 10) || 0;
    //     const updatedTime = existingTime + additionalTime;

    //     return { ...order, pickupTime: updatedTime };
    //   }
    //   return order;
    // });

    // setOrders({ data: updatedOrders });
    fetchOrders();
    setPickupTimeDialogOpenOpen(false);
  };

  const EditElement = (row) => (
    <>
      <IconButton
        aria-label="increase pick-up time"
        onClick={() => handleOpenDialog(row)}
        size="small"
      >
        <EditIcon />
      </IconButton>
      <PickupTimeDialog
        open={PickupTimeDialogOpen}
        onClose={handleCloseDialog}
        onUpdate={(additionalTime) => handleUpdatePickupTime(additionalTime, selectedRow)}
      />
    </>
  );

  const cellList = [
    { name: "", alignment: "", selector: "" },
    { name: "Order ID", alignment: "center", selector: "id", isId: true },
    { name: "Date", alignment: "center", selector: "createdAt", isDate: true },
    { name: "Customer Name", alignment: "center", selector: "customer_name", isText: true },
    { name: "Order Type", alignment: "center", selector: "type", isText: true },
    { name: "Address", alignment: "center", selector: "address", isText: true },
    { name: "Pick-up Time", alignment: "center", selector: "pickup_time", isDate: true },
    { name: "Amount", alignment: "right", selector: "amount", isPrice: true },
    { name: "Status", alignment: "center", selector: "status", isBadge: true },
    {
      name: "Increase Pick-up Time",
      alignment: "center",
      selector: "status",
      isEdit: true,
      Element: EditElement,
    },
  ];

  const subCellList = [
    { name: "Product Name", alignment: "", selector: "product.name", isText: true },
    { name: "Quantity", alignment: "center", selector: "quantity", isText: true },
    { name: "Default Ingredients", alignment: "", selector: "requiredIngrediants", isChip: true },
    {
      name: "Customized Ingredients",
      alignment: "",
      selector: "customIngrediants",
      isChip: true,
    },
    { name: "Size", alignment: "center", selector: "size", isText: true },
    { name: "Crust Type", alignment: "center", selector: "crust_type", isCrust: true },
    { name: "Gluten Free", alignment: "center", selector: "gluten_free", isBoolean: true },
    { name: "Amount", alignment: "right", selector: "price", isPrice: true },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {t("Order List")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <ExpandableTable
                  orderList={orders?.data}
                  count={orders?.total}
                  cellList={cellList}
                  subCellList={subCellList}
                  expandTitle={t("order:ORDER_DETAILS_TITLE")}
                  expandProperty="orderItems"
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  page={page}
                  setPage={setPage}
                  setSearch={setSearch}
                  handleOrderUpdate={handleOrderUpdate}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Loader open={loading} />
      <Footer />
    </DashboardLayout>
  );
}

export default OrderList;
