import React, { useCallback, useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Card, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Loader from "components/Loader";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ExpandableTable from "examples/Tables/ExpandableTable";
import { useLazyGetNewOrdersQuery } from "services/new-order.service";
import { useUpdateOrderMutation } from "services/order.service";
import { toast } from "react-toastify";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
function CurrentOrder() {
  const { t } = useTranslation();

  const [orders, setOrders] = useState({
    total: 0,
    data: [],
  });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [printItem, setPrintItem] = useState();

  const [getOrders] = useLazyGetNewOrdersQuery();
  const [updateOrder] = useUpdateOrderMutation();
  const [intervalId, setIntervalId] = useState(null);
  const fetchOrders = useCallback(async () => {
    setLoading(true);
    const { data, isSuccess, isError, error } = await getOrders();
    if (isSuccess) {
      setOrders(data);
      if (data.data.length > 0) {
        stopInterval();
        printOrders(0, data.data);
      } else {
        startInterval();
      }
    }

    if (isError) {
      console.log(error);
    }
    setLoading(false);
  });

  useEffect(() => {
    fetchOrders();
  }, []);

  const printOrders = (i, data) => {
    console.log(data[i]);
    setPrintItem(data[i]);
    setTimeout(() => {
      SaveAsPDFHandler(i, data);
    }, 1000);
  };

  const startInterval = () => {
    const id = setInterval(() => {
      fetchOrders();
    }, 10000);
    setIntervalId(id);
  };

  const stopInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const handleOrderUpdate = async (data) => {
    const ordersClone = { ...orders };

    ordersClone.data = ordersClone?.data?.map((product) => {
      if (product?.id === data?.id) {
        product = { ...product, is_printed: data?.is_printed };
      }
      return product;
    });
    setOrders(ordersClone);

    const result = await updateOrder({
      submitData: { status: data?.updatedStatus },
      id: data?.id,
    });
    if ("error" in result) {
      toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    } else {
      toast.success(result?.data?.msg);
    }
  };

  const SaveAsPDFHandler = (i, data) => {
    // if (!printItem) return;

    const dom = document.getElementById("print");
    toPng(dom)
      .then((dataUrl) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = dataUrl;
        img.onload = () => {
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "in",
            format: [3, 6],
          });

          const imgProps = pdf.getImageProperties(img);
          const imageType = imgProps.fileType;
          const pdfWidth = pdf.internal.pageSize.getWidth();

          const pxFullHeight = imgProps.height;
          const pxPageHeight = Math.floor((imgProps.width * 6) / 3);
          const nPages = Math.ceil(pxFullHeight / pxPageHeight);

          let pageHeight = pdf.internal.pageSize.getHeight();

          const pageCanvas = document.createElement("canvas");
          const pageCtx = pageCanvas.getContext("2d");
          pageCanvas.width = imgProps.width;
          pageCanvas.height = pxPageHeight;

          for (let page = 0; page < nPages; page++) {
            if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
              pageCanvas.height = pxFullHeight % pxPageHeight;
              pageHeight = (pageCanvas.height * pdfWidth) / pageCanvas.width;
            }

            const w = pageCanvas.width;
            const h = pageCanvas.height;
            pageCtx.fillStyle = "white";
            pageCtx.fillRect(0, 0, w, h);
            pageCtx.drawImage(img, 0, page * pxPageHeight, w, h, 0, 0, w, h);

            if (page) pdf.addPage();

            const imgData = pageCanvas.toDataURL(`image/${imageType}`, 1);
            pdf.addImage(imgData, imageType, 0, 0, pdfWidth, pageHeight);
          }

          pdf.save(`invoice-${printItem?.id}.pdf`);
          if (i < data.length) {
            printOrders(i + 1, data);
          } else {
            // setOrders([]);
            // setPrintItem(null);
            // startInterval();
          }
        };
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);
      });
  };

  const cellList = [
    { name: "", alignment: "", selector: "" },
    { name: "Order ID", alignment: "center", selector: "id", isId: true },
    { name: "Date", alignment: "center", selector: "createdAt", isDate: true },
    { name: "Customer Name", alignment: "center", selector: "customer_name", isText: true },
    // { name: "Order Type", alignment: "center", selector: "type", isText: true },
    // { name: "Address", alignment: "center", selector: "address", isText: true },
    { name: "Pick-up Time", alignment: "center", selector: "pickup_time", isDate: true },
    { name: "Amount", alignment: "right", selector: "amount", isPrice: true },
    // { name: "Status", alignment: "center", selector: "status", isBadge: true },
  ];

  const subCellList = [
    { name: "Product Name", alignment: "", selector: "product.name", isText: true },
    { name: "Quantity", alignment: "center", selector: "quantity", isText: true },
    { name: "Default Ingredients", alignment: "", selector: "requiredIngrediants", isChip: true },
    {
      name: "Customized Ingredients",
      alignment: "",
      selector: "customIngrediants",
      isChip: true,
    },
    { name: "Size", alignment: "center", selector: "size", isText: true },
    { name: "Crust Type", alignment: "center", selector: "crust_type", isCrust: true },
    { name: "Gluten Free", alignment: "center", selector: "gluten_free", isBoolean: true },
    { name: "Amount", alignment: "right", selector: "price", isPrice: true },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {t("Current Order")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <ExpandableTable
                  orderList={orders?.data}
                  count={orders?.total}
                  cellList={cellList}
                  subCellList={subCellList}
                  expandTitle={t("order:ORDER_DETAILS_TITLE")}
                  expandProperty="orderItems"
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  page={page}
                  setPage={setPage}
                  setSearch={setSearch}
                  handleOrderUpdate={handleOrderUpdate}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Loader open={loading} /> */}
      <Footer />
      {printItem && (
        <div className="p-4" id="print">
          <h1 className="text-center text-lg font-bold text-gray-900">INVOICE</h1>
          <div className="mt-6">
            <div className="mb-4 grid grid-cols-2">
              <span className="font-bold">Invoice Number:</span>
              <span>{printItem.id}</span>
              <span className="font-bold">Customer:</span>
              <span>{printItem.customer_name}</span>
              <span className="font-bold">Pickup Time:</span>
              <span>{new Date(printItem.pickup_time).toLocaleString()}</span>
            </div>

            <table className="w-full text-left">
              <thead>
                <tr className="border-y border-black/10 text-sm md:text-base">
                  <th>ITEM</th>
                  <th className="text-center">QTY</th>
                  <th className="text-right">PRICE</th>
                  <th className="text-right">AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {printItem.orderItems.map((item) => (
                  <tr key={item.id}>
                    <td className="w-full">{item.product.name}</td>
                    <td className="min-w-[50px] text-center">{item.quantity}</td>
                    <td className="min-w-[80px] text-right">${Number(item.price).toFixed(2)}</td>
                    <td className="min-w-[90px] text-right">
                      ${Number(item.price * item.quantity).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="mt-4 flex flex-col items-end space-y-2">
              <div className="flex w-full justify-between border-t border-black/10 pt-2">
                <span className="font-bold">Subtotal:</span>
                <span>${(printItem.amount - printItem.tax).toFixed(2)}</span>
              </div>
              <div className="flex w-full justify-between">
                <span className="font-bold">Tax:</span>
                <span>${printItem.tax.toFixed(2)}</span>
              </div>
              <div className="flex w-full justify-between border-t border-black/10 py-2">
                <span className="font-bold">Total:</span>
                <span className="font-bold">${printItem.amount.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}

export default CurrentOrder;
