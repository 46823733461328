/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, Grid } from "@mui/material";
import FileInput from "components/FileInput";
import FormButtons from "components/FormButtons";
import { useTranslation } from "react-i18next";
import { handleChange, validate } from "lib/joiHelper";
import Constants from "../../lib/Constants";
import { createCategorySchema, updateCategorySchema } from "./schema";

function AddCategoryDialog({ dialogOpen, handleClose, handleSubmitForm, data }) {
  const [formData, setFormData] = useState();
  const [error, setError] = useState({ ...data });
  const { t } = useTranslation();
  const schema = formData?.id ? updateCategorySchema(t) : createCategorySchema(t);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleNameChange = (event) => {
    const { dataClone, errorClone } = handleChange(event, schema, formData, error, formData);
    setFormData(dataClone);
    setError(errorClone);
  };

  const handleImageSelect = (selectedImage) => {
    const formDataClone = { ...formData };
    formDataClone.image = selectedImage;
    setFormData(formDataClone);
    if (error?.image) {
      setError({ ...error, image: null });
    }
  };

  const handleSubmit = () => {
    const errorClone = validate(formData, schema);
    if (errorClone) {
      setError(errorClone);
    } else {
      console.log(formData);
      const submitFormData = new FormData();
      submitFormData.append("name", formData?.name);
      if (formData.image) {
        submitFormData.append("image", formData?.image);
      }
      handleSubmitForm(submitFormData);
    }
  };

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>{t("categories:PRODUCT_CATEGORY")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <TextField
              label={t("common:NAME")}
              value={formData?.name}
              name="name"
              onBlur={handleNameChange}
              fullWidth
              error={error?.name?.length > 0}
              helperText={error?.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              sx={{ marginTop: "0.5em" }}
            />
          </Grid>
          <Grid item>
            <FileInput
              handleFileSelect={handleImageSelect}
              selectedFiles={formData?.image}
              label={t("common:IMAGE")}
              error={error?.image}
              fileTypes={Constants.IMAGE_FILE_TYPES}
            />
          </Grid>
          <Grid item>
            <FormButtons
              cancelLabel={t("common:CANCEL")}
              submitLabel={formData?.id ? t("common:UPDATE") : t("common:SAVE")}
              submitHandler={handleSubmit}
              cancelHandler={handleClose}
              removeTopBottomPadding
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddCategoryDialog;
