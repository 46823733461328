import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { FormControl } from "@mui/material";

// eslint-disable-next-line react/prop-types
export default function PickupTimeDialog({ open, onClose, onUpdate }) {
  const [time, setTime] = React.useState("5");

  const handleChange = (event) => {
    setTime(event.target.value);
  };

  const handleUpdate = () => {
    onUpdate(parseInt(time, 10));
    // onClose(); // Close the dialog after updating
  };

  return (
    <Dialog maxWidth={"sm"} open={open} onClose={onClose}>
      <DialogTitle>Select Time</DialogTitle>
      <DialogContent>
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
        >
          <FormControl>
            <Select autoFocus value={time} onChange={handleChange} sx={{ p: 2 }}>
              <MenuItem value="5">5 Min</MenuItem>
              <MenuItem value="10">10 Min</MenuItem>
              <MenuItem value="15">15 Min</MenuItem>
              <MenuItem value="20">20 Min</MenuItem>
              <MenuItem value="25">25 Min</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleUpdate}>Update</Button>
      </DialogActions>
    </Dialog>
  );
}
