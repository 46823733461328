/* eslint-disable react/prop-types */
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

const FormButtons = ({
  cancelLabel,
  submitLabel,
  submitHandler,
  cancelHandler,
  removeTopBottomPadding,
}) => {
  return (
    <MDBox pt={removeTopBottomPadding ? 0 : 3} pb={removeTopBottomPadding ? 0 : 3}>
      <Grid container display="flex" justifyContent="center" spacing={2} pt={3}>
        <Grid item>
          <MDButton variant="outlined" color="secondary" fullWidth onClick={cancelHandler}>
            {cancelLabel}
          </MDButton>
        </Grid>
        <Grid item>
          <MDButton variant="gradient" color="info" fullWidth onClick={submitHandler}>
            {submitLabel}
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default FormButtons;
