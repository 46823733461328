/* eslint-disable react/prop-types */
import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Constants from "lib/Constants";

function CountryDropDown({ onChange, onBlur, value, error, isViewOnly }) {
  return (
    <FormControl fullWidth>
      <InputLabel id="country-label" error={error}>
        Country
      </InputLabel>
      <Select
        labelId="country-label"
        id="country"
        label="Country"
        value={value}
        name="country"
        sx={{ height: 43 }}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth
        error={error}
        disabled={isViewOnly}
      >
        {Constants.COUNTRY.map((country, index) => (
          <MenuItem key={index} value={country.value}>
            {country.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CountryDropDown;
