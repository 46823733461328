/* eslint-disable react/prop-types */
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import MultiCheckPicker from "components/MultiCheckPicker";
import Constants from "lib/Constants";
import { useState } from "react";

function DynamicInputs({ handleOnChange, t, error, ingredients, inputData, handleLabelChange }) {
  const [initializeState, setInitializeState] = useState({
    defaultIngredients: inputData?.ingrediants?.required?.length
      ? [...inputData.ingrediants.required]
      : [],
    optIngredients: inputData?.ingrediants?.optional?.length
      ? [...inputData.ingrediants.optional]
      : [],
    sub_category: inputData?.sub_category || "",
  });

  const handleChangeSubInput = (name, value) => {
    // const { name, value } = event.target;
    const dataClone = { ...initializeState, [name]: value };
    setInitializeState(dataClone);
  };

  const onBlurHandler = () => {
    const required = initializeState?.defaultIngredients?.map((item) => item?.value) || [];
    const optional = initializeState?.optIngredients?.map((item) => item?.value) || [];
    handleOnChange({ ...initializeState, defaultIngredients: required, optIngredients: optional });
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormControl
          fullWidth
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FormLabel sx={{ fontSize: "15px" }}>{t("product:SUB_CATEGORY")}</FormLabel>
          <RadioGroup
            row
            name="sub_category"
            onChange={(e) => handleChangeSubInput(e.target.name, e.target.value)}
            onBlur={() => onBlurHandler()}
            value={initializeState?.sub_category}
            style={{ marginLeft: "24px" }}
          >
            <FormControlLabel value="PREMIUM" control={<Radio />} label={t("product:PREMIUM")} />
            <FormControlLabel value="CLASSIC" control={<Radio />} label={t("product:CLASSIC")} />
          </RadioGroup>
          {error?.sub_category?.length > 0 && (
            <FormHelperText error>{t(error?.sub_category)}</FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="color-label" required>
            {t("product:PRODUCT_LABEL_COLOR")}
          </InputLabel>
          <Select
            labelId="color-label"
            id="color"
            label={t("product:PRODUCT_LABEL_COLOR")}
            name="label_color"
            sx={{ height: 43 }}
            className="product-label-select"
            required
            fullWidth
            value={inputData.label_color}
            onChange={(event) => handleLabelChange(event.target.name, event.target.value)}
            error={error?.label_color?.length > 0}
          >
            {Constants.COLORS.map((color) => (
              <MenuItem
                key={color.value}
                value={color.value}
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
              >
                {color.label}
                <span className="color-dot" style={{ backgroundColor: color.value }} />
              </MenuItem>
            ))}
          </Select>
          {error?.label_color?.length > 0 && (
            <FormHelperText error>{t(error?.label_color)}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <FormGroup row>
            <MultiCheckPicker
              data={ingredients}
              label={t("product:DEFAULT_INGREDIENTS")}
              onChange={(event, value) => handleChangeSubInput("defaultIngredients", value)}
              onBlur={() => onBlurHandler()}
              error={error?.defaultIngredients}
              value={initializeState?.defaultIngredients}
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <FormGroup row>
            <MultiCheckPicker
              data={ingredients}
              label={t("product:OPTIONAL_INGREDIENTS")}
              onChange={(event, value) => handleChangeSubInput("optIngredients", value)}
              onBlur={() => onBlurHandler()}
              value={initializeState?.optIngredients}
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </>
  );
}

export default DynamicInputs;
