/* eslint-disable react/prop-types */
import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as EditIcon, Visibility, Delete as DeleteIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import MDTypography from "components/MDTypography";

function TableActions({ record, onEdit, onView, onDelete }) {
  const { t } = useTranslation();

  return (
    <MDTypography variant="caption" fontWeight="medium">
      <Tooltip title={t("common:EDIT")}>
        <IconButton aria-label="edit" color="secondary" onClick={() => onEdit(record.id)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("common:VIEW")}>
        <IconButton aria-label="view" color="secondary" onClick={() => onView(record.id)}>
          <Visibility />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("common:DELETE")}>
        <IconButton aria-label="delete" color="secondary" onClick={() => onDelete(record.id)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </MDTypography>
  );
}

export default TableActions;
