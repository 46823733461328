/* eslint-disable react/prop-types */
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const TableCellWithSubtitle = ({ title, subtitle }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{subtitle}</MDTypography>
    </MDBox>
  </MDBox>
);

export default TableCellWithSubtitle;
