import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/sign-in";
import SignUp from "layouts/authentication/sign-up";
// @mui icons
import Icon from "@mui/material/Icon";
import {
  TableView,
  ReceiptLong,
  Notifications as NotificationsIcon,
  Person,
  Assignment,
  LocalPizza,
  Category,
} from "@mui/icons-material";
import Products from "layouts/products";
import AddProduct from "layouts/products/AddProduct";

import Ingredients from "layouts/ingredients";
import OrderList from "layouts/orderList";
import UserList from "layouts/userList";
import TransactionsList from "layouts/transactions";
import ProductsCategories from "layouts/productCategory";
import CurrentOrder from "layouts/current-order";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  {
    type: "invisible",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "invisible",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "invisible",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "invisible",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "invisible",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "invisible",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "invisible",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <Category fontSize="small" />,
    route: "/categories",
    component: <ProductsCategories />,
    protected: true,
    isStoreMenu: true,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <LocalPizza fontSize="small" />,
    route: "/products",
    component: <Products />,
    protected: true,
    isStoreMenu: true,
  },
  {
    type: "",
    name: "Add Product",
    key: "add-product",
    route: "/products/add",
    component: <AddProduct />,
    protected: true,
    isStoreMenu: false,
  },
  {
    type: "invisible",
    name: "Edit",
    key: "edit-store",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/products/edit/:productId",
    component: <AddProduct />,
    protected: false,
    isStoreMenu: false,
  },
  {
    type: "invisible",
    name: "View",
    key: "view-store",
    icon: <Icon fontSize="small">visibility</Icon>,
    route: "/products/view/:productId",
    component: <AddProduct />,
    protected: true,
    isStoreMenu: true,
  },
  {
    type: "invisible",
    name: "Ingredients",
    key: "ingredients",
    icon: <Icon fontSize="small">soup_kitchen</Icon>,
    route: "/ingredients",
    component: <Ingredients />,
    protected: true,
    isStoreMenu: false,
  },
  {
    type: "invisible",
    name: "Add",
    key: "add-store",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/stores/add",
    component: <Profile />,
    protected: true,
    isStoreMenu: false,
  },
  {
    type: "invisible",
    name: "Edit",
    key: "edit-store",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/stores/edit/:storeId",
    component: <Profile />,
    protected: false,
    isStoreMenu: false,
  },
  {
    type: "invisible",
    name: "View",
    key: "view-store",
    icon: <Icon fontSize="small">visibility</Icon>,
    route: "/stores/view/:storeId",
    component: <Profile />,
    protected: true,
    isStoreMenu: false,
  },
  {
    type: "collapse",
    name: "Order List",
    key: "order-list",
    icon: <Icon fontSize="small">shopping_cart_checkout</Icon>,
    route: "/order-list",
    component: <OrderList />,
    protected: true,
    isStoreMenu: true,
  },
  {
    type: "invisible",
    name: "User List",
    key: "user-list",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user-list",
    component: <UserList />,
    protected: true,
    isStoreMenu: false,
  },
  {
    type: "collapse",
    name: "Transaction List",
    key: "transaction-list",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/transaction-list",
    component: <TransactionsList />,
    protected: true,
    isStoreMenu: true,
  },
  {
    type: "collapse",
    name: "Current Order",
    key: "current-order",
    icon: <Icon fontSize="small">shopping_cart_checkout</Icon>,
    route: "/current-order",
    component: <CurrentOrder />,
    protected: true,
    isStoreMenu: true,
  },
];

export default routes;
