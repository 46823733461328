/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import MDTypography from "components/MDTypography";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
  Chip,
  Grid,
  TablePagination,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Constants from "../../../lib/Constants";
import { isSuperAdmin } from "../../../lib/helperFuctions";

function Row(props) {
  const {
    row,
    columnList,
    subCellList,
    expandTitle,
    expandProperty,
    translation,
    handleOrderUpdate,
  } = props;
  const [open, setOpen] = React.useState(false);

  const filteredColumn = columnList.filter((obj) => {
    return Object.values(obj).every((val) => val !== "");
  });

  const getChipList = (chipValues) => {
    if (chipValues && chipValues.length > 0) {
      return chipValues.map((chip) => {
        return <Chip key={chip.id} label={chip.name} size="small" />;
      });
    }
    return <Chip label={chipValues[0]?.name} size="small" />;
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {filteredColumn?.map((column) => {
          return (
            <TableCell component="th" scope="row" align={column.alignment || "left"}>
              {column.isBadge && (
                <Select
                  labelId="color-label"
                  id="color"
                  name="label_color"
                  sx={{ height: 43 }}
                  className="product-label-select"
                  fullWidth
                  disabled={isSuperAdmin()}
                  value={row[column.selector]}
                  onChange={(event) =>
                    handleOrderUpdate({ id: row.id, updatedStatus: event.target.value })
                  }
                >
                  {Constants.ORDER_STATUS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                      <span className="color-dot" style={{ backgroundColor: item.color }} />
                    </MenuItem>
                  ))}
                </Select>
              )}

              {column.isPrice && (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  ${row[column.selector]}
                </MDTypography>
              )}
              {column.isId && (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  #{row[column.selector]}
                </MDTypography>
              )}
              {column.isText && (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {row[column.selector] || "-"}
                </MDTypography>
              )}
              {column.isEdit && column.Element && column.Element(row)}
              {column.isDate && (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  {row[column.selector]
                    ? moment(row[column.selector]).format("DD/MM/YYYY hh:mm:ss A")
                    : "-"}
                </MDTypography>
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {expandTitle || translation("common:DETAILS")}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead style={{ display: "table-header-group" }}>
                  <TableRow>
                    {subCellList?.map((subCell) => {
                      return <TableCell>{subCell?.name}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row[expandProperty].map((itemsRow) => {
                    return (
                      <TableRow key={`${row.orderId}${itemsRow.productName}`}>
                        {subCellList?.map((subCell) => {
                          return (
                            <TableCell
                              key={itemsRow?.id}
                              component="th"
                              scope="row"
                              align={subCell.alignment || "left"}
                            >
                              {subCell.isChip &&
                                (itemsRow[subCell.selector] &&
                                itemsRow[subCell.selector].length > 0 ? (
                                  <Stack direction="row" spacing={1} style={{ display: "inline" }}>
                                    {getChipList(itemsRow[subCell.selector])}
                                  </Stack>
                                ) : (
                                  <MDTypography variant="caption" color="text" fontWeight="medium">
                                    -
                                  </MDTypography>
                                ))}

                              {subCell.isText && (
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                  {subCell.selector.includes(".")
                                    ? itemsRow[subCell.selector.split(".")[0]]?.[
                                        subCell.selector.split(".")[1]
                                      ]
                                    : itemsRow[subCell.selector] || "-"}
                                </MDTypography>
                              )}
                              {subCell.isPrice && (
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                  ${itemsRow[subCell.selector] || "-"}
                                </MDTypography>
                              )}
                              {subCell.isBoolean &&
                                (() => {
                                  if (typeof itemsRow[subCell.selector] === "boolean") {
                                    return (
                                      <MDTypography
                                        variant="caption"
                                        color="text"
                                        fontWeight="medium"
                                      >
                                        {itemsRow[subCell.selector] ? "Yes" : "No"}
                                      </MDTypography>
                                    );
                                  }
                                  return (
                                    <MDTypography
                                      variant="caption"
                                      color="text"
                                      fontWeight="medium"
                                    >
                                      -
                                    </MDTypography>
                                  );
                                })()}
                              {subCell.isCrust && (
                                <MDTypography variant="caption" color="text" fontWeight="medium">
                                  {Constants.CRUST_TYPE[itemsRow[subCell.selector]] || "-"}
                                </MDTypography>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function ExpandableTable({
  orderList,
  cellList,
  subCellList,
  expandTitle,
  expandProperty,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  count,
  setSearch,
  handleOrderUpdate,
}) {
  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end" direction="row">
        <Grid item xs={3} justifyContent="center" display="flex" direction="row">
          <TextField
            variant="outlined"
            label={t("Search Order ID")}
            // value={searchValue}
            // onChange={handleSearch}
            style={{ display: "flex", justifyContent: "center" }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead style={{ display: "table-header-group" }}>
            <TableRow>
              {cellList.map((cell) => {
                return <TableCell align={cell.alignment || "left"}>{cell.name}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {orderList &&
              orderList?.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  columnList={cellList}
                  subCellList={subCellList}
                  expandTitle={expandTitle}
                  expandProperty={expandProperty}
                  translation={t}
                  handleOrderUpdate={handleOrderUpdate}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPTIONS}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default ExpandableTable;
